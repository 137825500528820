import styles from '@app/styles/NotFound.module.scss';

export default function Custom404() {
  return (
    <div className={styles.wrapper}>
      <h1 className={styles.primaryHeading}>404</h1>
      <div className={styles.text}>Page Not Found or you don`t have permission</div>
    </div>
  );
}
